import { version } from '../../../../package.json';

export const environment = {
  production: false,
  ENV_NAME: 'grey',
  AUTH0_DOMAIN: 'auth0.tiime.fr',
  AUTH0_CLIENT_ID: 'yzIvgseLZ42FSJViygQmap4F5Z0fWQcN',
  AUTH0_REALM: 'Chronos-dev',
  AUTH0_AUDIENCE: 'https://chronos-dev/',
  AUTH0_WHITE_DOMAINS: ['chronos-review-grey.preprod.tiime.tech'],
  API_URL: 'https://chronos-review-grey.preprod.tiime.tech',
  APPS_URL: 'https://apps.chronos-review-grey.preprod.tiime.tech',
  SENTRY_DNS: 'https://542bc4b29ff44173914c808812e153ab@sentry.io/1334361',
  VERSION: version
};
